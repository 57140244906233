import Styled from "styled-components";
import React from "react";
import { animated, useSpring, easings } from "@react-spring/web";
import { Link } from "react-router-dom";
import arrow from "../images/arrow_right_alt.svg";

const StyledToolbar = Styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  font-family: 'Founders Regular';
  font-size: 16px;
  margin-top: 40px;
  span {
    margin-top: 6px;
  }
`;

const StyledStatus = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 5;
  font-family: "Founders Regular";
  font-size: 16px;
  span {
    margin-top: 6px;
  }
`;

const StyledStage = Styled.div`
  background: #a81614;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
`;

const StyledLink = Styled(Link)`
    img {
      transform: rotate(180deg);
      transition: transform .2s;
    }
    &:hover img{
      transform:translateX(-5px) rotate(180deg);
    }
`

function Toolbar({ stage }) {
  let content;

  const CheckIcon = () => (
    <svg className='d-inline-block me-2' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1016_309)'>
        <path
          d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z'
          fill='#009966'
        />
      </g>
    </svg>
  );
  switch (stage) {
    case 1:
      content = (
        <StyledStatus>
          <StyledStage>1</StyledStage>
          <h3>Get ready to rumble!</h3>
          <p>Hold your phone close to your stomach</p>
        </StyledStatus>
      );
      break;
    case 2:
      content = (
        <StyledStatus>
          <StyledStage><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg></StyledStage>
          <h3>Recording complete</h3>
          <p>Review your rumble for submission</p>
        </StyledStatus>
      );
      break;
    case 3:
      content = (
        <StyledStatus>
          <StyledStage>2</StyledStage>
          <h3>Enter your details</h3>
          <p>Complete your contact details & confirm your entry.</p>
        </StyledStatus>
      );
      break;
    case 4:
      content = (
         <StyledStatus>
          <h1>Thank you for entering</h1>
          <p className='mb-4'>Thanks to you and your stomach, crave_ware gets smarter and sharper with each and every rumble. Good luck!</p>
           <StyledLink
                className="btn rr-button d-inline btn-primary mt-0 mb-2"
                to={'/'}
                > <img src={arrow} width={'24px'} height={'auto'} /> Back to home
                {/* <a className="btn rr-button btn-primary mt-0 mb-2" target="_blank" rel="noreferrer">Submit your rumble</a> */}
            </StyledLink>
        </StyledStatus>
      );
      break;
    case 5:
      content = (
        <>
          <div className='toolbar-loader disconnected'></div> <span>Menu matching in progress</span>
        </>
      );
      break;
    case 6:
      content = (
        <>
          <CheckIcon />
          <span>Menu item matched</span>
        </>
      );
      break;
    default:
      content = null;
  }

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000, delay: 1000, easing: easings.easeInOut }
  });


  return (
      <StyledToolbar
        style={fadeIn} >
        <div className='d-flex align-items-center ff-body'>{content}</div>
      </StyledToolbar>
  );
}

export default Toolbar;
