import Card from "react-bootstrap/Card";
import { media } from "./media/media";
import Styled from "styled-components";
import VideoContent from './VideoContent';

const StyledCard = Styled(Card)`

  text-align: left;
  /* padding: 16px; */
  margin: auto 20px;
  @media ${media.mobileS} {
        /* width: 90vw; */
    }

    @media ${media.tablet} {
        width: 40vw;
    }

    @media ${media.laptop} {
        display: flex;
        max-width: 400px;
        margin-left: 40px;
        flex-direction: column;
        align-items: start;
    }
`;

const StyledCardTitle = Styled(Card.Title)`
  color: #A81614;
  font-family: "Goudy";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.1px;
`;
function CardContent() {
  return (
    <StyledCard className='rounded-0' style={{}}>
      <VideoContent video="gamer" />
      <Card.Body>
        <StyledCardTitle>“When I’m too busy climbing the leaderboard crave_ware answers my chicken cravings for me”</StyledCardTitle>
        <Card.Text>— Todd Barnett, Gamer</Card.Text>
      </Card.Body>
    </StyledCard>
  );
}

export default CardContent;
