// react footer component
import React, {useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";
import useViewport from '../hooks/useViewport';

const StyledFooter = styled.div`
  background-color: #A50115;
  color: #ffffff;
  position: ${props => props.position || 'absolute'};
  bottom: ${props => props.isMobile ? '-90px' : '-52px'};
  left: 0;
  right: 0;
  z-index: 99;
  ul {
    display: flex;
    list-style: none;
    font-family: "Founders Medium";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    margin: 0;
    padding: 0;
    li {
      padding: 0 4px;
    }
  }
`;

function Footer({position}) {
  	const { width } = useViewport(); // Custom hook to get current viewport width
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(width < 768); // Or any other width you consider as mobile
	}, [width]);
  return (
    <StyledFooter isMobile={isMobile} position={position} className='py-2'>
      <Container>
        <Row className='justify-content-between'>
          <Col sm={12} md={6} className='d-flex justify-content-md-start justify-content-center'>
            <ul>
              <li><a className="text-white text-decoration-none" target="_blank" href='https://www.redrooster.com.au/terms/'>Terms & conditions</a></li>
              <li>•</li>
              <li><a className="text-white text-decoration-none" target="_blank" href='https://www.redrooster.com.au/privacy/'>Privacy policy</a></li>
            </ul>
          </Col>
          <Col sm={12} md={6} className='d-flex justify-content-md-end justify-content-center'>
            <ul>
              <li>©2024 Red Rooster® All rights reserved.</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
}

export default Footer;
