import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import PhoneImg from "../images/phone.png";
import { media } from "./media/media";
import Styled from "styled-components";

const StyledImage = Styled.div`
  display: flex;
  margin: auto;
  @media ${media.mobileS} {
    width: 180px;
  }

  @media ${media.tablet} {
    width: 180px;
  }

  @media ${media.laptop} {
    width: 180px;
    margin-left: 20px;
    flexDirection: column;
    alignItems: center;
  }
`;

function PhoneImage() {
  return (
    <StyledImage>
      <Image src={PhoneImg} width={"100%"} height={"auto"} />
    </StyledImage>
  );
}

export default PhoneImage;
