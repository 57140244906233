import Styled from "styled-components";

const StyledHeading = Styled.h2`
    color: #ffffff;
    font-size: 40px;
    line-height: 0.8;
    margin-bottom: 28px;
    font-family: "Goudy";
    span {
        font-size: 80px;
        color: #CFB578;
        font-family: "Goudy";
        display: block;
    }
    z-index: 10;
    position: relative;
`;

function FormHero() {
  return (
    <>
      <StyledHeading className='p-4'>
        Submit your rumble <span>& WIN</span> a year's supply of free chicken
      </StyledHeading>
    </>
  );
}

export default FormHero;
