import React from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import arrow from "../../images/arrow_right_alt.svg";
import { media, breakPoints } from "../../components/media/media";

const Header = Styled.div`
    width: 100%;
    height: 100px;
    position: fixed;
    z-index: 1;
`;

const Rumble = Styled.div`
    position: absolute;
    right: 20px;
    top: 60px;
    text-align: right;
    padding: 0px;
    @media ${media.tablet} {
    top: 20px;
    }

`;

const StyledLink = Styled(Link)`
    img {

        transition: transform .2s;
    }
    &:hover img{
        transform: translateX(5px);
    }
`

export default function Logo() {
  return (
    <Header>
        <Rumble>
            <StyledLink
                className="btn rr-button d-inline btn-primary mt-0 mb-2"
                to={'/win'}
                >Register your interest <img src={arrow} width={'24px'} height={'auto'} />
                {/* <a className="btn rr-button btn-primary mt-0 mb-2" target="_blank" rel="noreferrer">Submit your rumble</a> */}
            </StyledLink>
        </Rumble>
    </Header>
  );
}
