import React from "react";
import styled from "styled-components";

const StyledVolumeCircle = styled.div`
  width: ${({ volume }) => 160 + volume * 10}px;
  height: ${({ volume }) => 160 + volume * 10}px;
  border-radius: 50%;
  background-color: #a81614;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, box-shadow 0.1s ease-in, border-width 0.2s ease-out;
  z-index: 1;
  border: #571719 solid;
  border-width: ${({ volume }) => volume * 8}px;
  position: absolute;
  box-sizing: content-box;
  box-shadow: 0 0 0 ${({ volume }) => volume * 4}px #212121;

`;
export default function VolumeCircle({ volume }) {
  return <StyledVolumeCircle volume={volume} />;
}
