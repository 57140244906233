import React from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '../../media/media';
import mic from '../../../images/logo_mic_craveare.png';

// Animation for rotating the SVG containing the text
const rotate = keyframes`
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

// Styled container for the circle and the SVG
const CircleContainer = styled.div`
  position: absolute;
  
  @media ${media.mobileS} {
    right: 20px;
    top: -130px;
  }

  @media ${media.tablet} {
    right: 50px;
    top: -100px;
  }

  @media ${media.laptop} { 
    right: 100px;
    top: -100px;
  } 

  width: 120px; // Adjusted to 120px
  height: 120px; // Adjusted to 120px
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Styled container for the circle and the SVG
const BlackCircle = styled.div`
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: black;
`;

// Styled SVG for the rotating text path
const SVGTextPath = styled.svg`
  color: pink;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  animation: ${rotate} 10s linear infinite; // Adjust timing as needed
  will-change: transform;
`;

// Styled image (placeholder)
const Image = styled.img`
  width: 20px; // Adjust size as needed
  transform: rotate3d(0, 0, 1, 20deg);
  will-change: transform;
  height: auto;
  z-index: 1; // Ensure the image is above the rotating text
`;

const Roundel = () => (
  <CircleContainer>
    <Image src={mic} alt="Microphone" /> {/* Adjusted image size */}
    <SVGTextPath viewBox="0 0 120 120"> {/* Adjusted viewBox to match new circle size */}
      <defs>
        <path id="circlePath" d="M60,60 m-45,0 a45,45 0 1,1 90,0 a45,45 0 1,1 -90,0" />
      </defs>
      <text fill="#CFB578" style={{"fontFamily":"Goudy"}}>
        <textPath xlinkHref="#circlePath" startOffset="50%" textAnchor="middle">Turn your mic on * Turn your mic on * </textPath>
      </text>
    </SVGTextPath>
    <BlackCircle />
  </CircleContainer>
);

export default Roundel;