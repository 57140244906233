import styled from "styled-components";
import React, { useRef, useEffect, useState } from "react";
import { useInView } from "@react-spring/web";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import useViewport from '../hooks/useViewport';

import { Container, Row, Col } from 'react-bootstrap';

//images
import drumstick from "../images/drumstick.png";
import drumstick2 from "../images/drumstick2.png";
import fritter from "../images/fritter.png";
import fritter2 from "../images/fritter2.png";
import mic from "../images/mic.png";
import rulerLeft from "../images/rulerLeft.png";
import rulerRight from "../images/rulerRight.png";

//sounds
import rumbleSound1 from "../components/sounds/TIN_GURGLE_01.wav";

//parallax images
import Drumstick from "../components/parallax/drumstick/drumstick";
import Drumstick2 from "../components/parallax/drumstick/drumstick2";
import Fritter from "../components/parallax/fritter/fritter";
import Fritter2 from "../components/parallax/fritter/fritter2";
import Rrnd from "../components/parallax/rrnd/rrnd";
import RevolvingMic from "../components/revolving_mic/revolving_mic";
import Mic from "../components/parallax/mic/mic";
import Roundel from "../components/parallax/roundel/roundel";
// import CravewareVisualiser from "../components/parallax/craveware/craveware";
import rrTShirtVideo from "../components/video/REROWG0103_RR_T_Shirt.mp4";
import rrExplodedMic from "../components/video/section-2_3dmic_build.mp4";
import rrPhone from "../components/video/section-4_phone.mp4";
import rrSensory from "../components/video/SENSORY_FILM_loop.mp4";


//components
import AnimatedContent from "../components/AnimatedContent";
import CardContent from "../components/CardContent";
import DarkCardContent from "../components/DarkCardContent";
import VideoContent from "../components/VideoContent";
import PhoneImage from "../components/PhoneImage";
import Cta from "../components/cta/cta";
import CraveWaveStatic from "../components/parallax/craveware/craveware_static";
import Footer from "../components/Footer";
import { media, breakPoints } from "../components/media/media";
import RegisterForm from '../components/form/RegisterForm'


const url = (name, wrap = false) => `${wrap ? "url(" : ""}${name}}`;

const Containerz = styled.div`
	background: radial-gradient(88.02% 88.02% at 50% 50%, rgba(255, 65, 16, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), #a81614;
	overflow-y: scroll;
`;

const Grid = styled.div`
	padding: 0px;
	margin-bottom: 15px; // Adds space between the columns when stacked
	text-align: center;
`;

const Video = styled.video`

	@media ${media.mobileS} {
		display:none;
	}

	@media ${media.tablet} {
		display:none;
	}

	@media ${media.laptop} {
		display:block;
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		left: 0;
		zIndex: -1;
	}
`;

const VideoSkin = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	background: #000;
	opacity: 0.6;
	z-index: 0;
    position: absolute;
`;

const LastVideo = styled.video`
	margin-top:250px;

	@media ${media.mobileS} {
		position: relative;
		width: 100%;
		height: auto;
		object-fit: contain;
		left: 0;
		zIndex: 0;
		margin-top:250px;
	}

	@media ${media.tablet} {
		position: relative;
		width: 100%;
		height: auto;
		object-fit: contain;
		left: 0;
		zIndex: 0;
		margin-top:200px;

	}
	@media ${media.laptop} {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: contain;
		left: 0;
		zIndex: -2;
		margin-top:100px;

	}
	@media ${media.laptopL} {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: contain;
		left: 0;
		zIndex: -2;
		margin-top:'0';

	}

`;

//<div style="height: 100%; background: radial-gradient(88% 88% at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(23, 23, 23, 0.3) 60%), rgb(23, 23, 23); z-index: -2;"></div>

function Home() {

	const [ref, isInView] = useInView({
		threshold: 0.5,
		triggerOnce: false,
	});

	const parallax = useRef(Parallax);
	const [scroll, setScroll] = useState(0);

	const { width } = useViewport(); // Custom hook to get current viewport width
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(width < 960); // Or any other width you consider as mobile
	}, [width]);

	const handleScroll = () => {
		if (parallax.current) {
			setScroll(Math.floor(Math.round(parallax.current.current)));
		}
	};

	useEffect(() => {
		const container = document.querySelector(".parallax");
		container.addEventListener("scroll", handleScroll);
		return () => {
			container.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const calcHeight = () => {

		const screenWidth = window.innerWidth;
		let pages;

		switch (true) {
			case screenWidth >= breakPoints.desktop:
				pages = 5;
				break;
			case screenWidth >= breakPoints.laptopL:
				pages = 5;
				break;
			case screenWidth >= breakPoints.laptop:
				pages = 5.8;
				break;
			case screenWidth >= breakPoints.tablet:
				pages = 6.8;
				break;
			case screenWidth >= breakPoints.mobileL:
				pages = 7.2;
				break;
			case screenWidth >= breakPoints.mobileM:
				pages = 8.2;
				break;
			case screenWidth >= breakPoints.mobileS:
				pages = 8.2;
				break;
			default:
				pages = 8.2;
		}

		return pages;
	};

	const calcWidth = () => {
		let test = window.innerWidth;
		let mob = test < 1000 ? true : false;
		return mob;
	}

	/* Important to calculate the page heights when component did mount!  */

	const [dynamicPages, setDynamicPages] = useState(calcHeight());
	const [dynamicWidth, setDynamicWidth] = useState(false);

	useEffect(() => {
		setDynamicPages(calcHeight());
		setDynamicWidth(calcWidth());
	}, []);

	return (
		<>
			<Containerz>

				{/* <Cta /> */}

				<Parallax ref={parallax} className='parallax' pages={dynamicPages}>

					{/* Start Top Background */}

					<ParallaxLayer
						offset={isMobile ? 0 : 0}
						speed={0}
						style={{
							background: "radial-gradient(88% 88% at 50% 50%, rgba(255, 65, 16, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #A81614",
							zIndex: "-1",
						}}
					>
						<ParallaxLayer
							offset={-0.6}
							speed={-0.2}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "right",
								zIndex: "-1",
							}}
						>
							<Drumstick drumstick={drumstick} />
							<Drumstick2 drumstick={drumstick2} style={{ width: "100%" }} />
						</ParallaxLayer>

						<ParallaxLayer
							offset={-0.1}
							speed={0.1}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								zIndex: "10",
							}}
						>
							<Rrnd />
						</ParallaxLayer>

						<ParallaxLayer
							offset={0.13}
							speed={0.50}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								zIndex: "-1",
							}}
						>
							<CraveWaveStatic />
						</ParallaxLayer>

						<ParallaxLayer offset={0.9} speed={0.06} style={{ pointerEvents: "none" }}>
							<Roundel />
						</ParallaxLayer>

						<ParallaxLayer
							offset={0.3}
							speed={-0.1}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "left",
								zIndex: "-1.5",
								width: "75%",
							}}
						>

							<Container fluid={'xxl'}>
								<Row>
									<Col lg={{ span: 4, offset: 1 }} xs={12} className="order-2 order-lg-1">
										<Grid>
											<AnimatedContent
												isInView={isInView}
												styleType={"small"}
												heading={[""]}
												halign='left'
												para='An AI Automation software that listens for chicken cravings and answers them on your behalf.'
											/>
										</Grid>
									</Col>
									<Col lg={{ span: 4, offset: 0 }} xs={12} className="order-1 order-lg-2">
										<Grid>

										</Grid>
									</Col>
									<Col lg={{ span: 4, offset: 0 }} xs={12} className="d-flex align-items-end order-3 order-lg-3" >
										<Grid>

										</Grid>
									</Col>
								</Row>
							</Container>


						</ParallaxLayer>

						<ParallaxLayer
							offset={-0.25}
							speed={-0.4}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "right",
								pointerEvents: "none",
							}}
						>
							<img src={rulerRight} style={{ marginRight: "0", width: "20px" }} />
						</ParallaxLayer>

						<ParallaxLayer
							offset={0.25}
							speed={-0.4}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "left",
								pointerEvents: "none",
							}}
						>
							<img src={rulerLeft} style={{ marginLeft: "0", width: "20px" }} />
						</ParallaxLayer>

					</ParallaxLayer>

					{/* End Top Background */}

					{/* Background 2 */}

					<ParallaxLayer
						offset={isMobile ? 1 : 1}
						speed={0}
						style={{
							background: "radial-gradient(88% 88% at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(23, 23, 23, 0.3) 60%), rgb(23, 23, 23)",
						}}
					>
						<VideoSkin />
						<Video
							autoPlay
							loop
							muted
							style={{
								zIndex: "-1",
							}}
						>

							<source src={rrExplodedMic} type='video/mp4' />
						</Video>

							<ParallaxLayer
								offset={dynamicWidth ? 0.4 : -0}
								speed={0.1}
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
									width: "100%",
								}}
							>

							<Container fluid={'xxl'}>
								<Row>
									<Col lg={{ span: 4 }} xs={12} className="order-2 order-lg-1">
										<Grid>
											<DarkCardContent />
										</Grid>
									</Col>
									<Col lg={{ span: 5, offset: 0 }} xs={12} className="order-1 order-lg-2">
										<Grid>
											<VideoContent video='intro' />
										</Grid>
									</Col>
									<Col lg={{ span: 3 }} xs={12} className="d-flex align-items-end order-3 order-lg-3" >
										<Grid>
											<AnimatedContent
												// ref={ref}
												isInView={isInView}
												halign='end'
												heading={["instant", "rumbles", "detection"]}
												para='We taught CRAVE_WARE to speak fluent stomach by collecting over a hundred hours of hungry rumbles from busy Aussies. We then fed them into a cutting-edge machine learning algorithm, specifically a Convolutional Neural Network (CNN) developed in TensorFlow.js – Which translates to some serious tech.'
											/>
										</Grid>
									</Col>
								</Row>
							</Container>

						</ParallaxLayer>

					</ParallaxLayer>
					{/* End 2 Background */}

					{/* Background 3 */}
					<ParallaxLayer
						offset={isMobile ? 2 : 2}
						speed={0}
						style={{
							top: `${dynamicWidth ? (dynamicPages * 100) : 0}px`
						}}
					>
						<VideoSkin />
						<Video
							autoPlay
							loop
							muted
							style={{
								zIndex: "-1",
							}}
						>
							<source src={rrSensory} type='video/mp4' />
						</Video>

						<ParallaxLayer
							offset={-0.75}
							speed={-0.24}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "left",
								pointerEvents: "none",
							}}
						>
							<Mic mic={mic} />
						</ParallaxLayer>

						<ParallaxLayer
							offset={0}
							speed={0.1}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Container fluid={'xxl'}>
								<Row>
									<Col lg={4} xs={12} className="order-2 order-lg-1">
										<Grid>
											<AnimatedContent
												// ref={ref}
												isInView={isInView}
												halign='start'
												heading={["instant", "menu", "matching"]}
												para='Our CRAVE_WARE algorithm measures the duration and intensity of each rumble and matches it to a corresponding Red Rooster item. Guaranteeing every craving meets the meal of their dreams.'
											/>
										</Grid>
									</Col>
									<Col lg={4} xs={12} className="order-3 order-lg-2">
										<Grid>

										</Grid>
									</Col>
									<Col lg={4} xs={12} className="order-1 order-lg-3">
										<Grid>
											<VideoContent video='sensory' style={{}} />
										</Grid>
									</Col>
								</Row>
							</Container>
						</ParallaxLayer>

					</ParallaxLayer>
					{/* End 3 Background */}

					{/* Background 4 */}

					<ParallaxLayer
						offset={isMobile ? 3 : 3}
						speed={0}
						style={{
							top: `${dynamicWidth ? (dynamicPages * 100) : 0}px`,
						}}
					>
						<VideoSkin />
						<Video
							autoPlay
							loop
							muted
							style={{
								zIndex: "-1",
							}}
						>
							<source src={rrPhone} type='video/mp4' />
						</Video>

						<ParallaxLayer
							offset={-0.05}
							speed={0.22}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								width: "100%",
								zIndex: 10,
								pointerEvents: "none"
							}}
						>
							<Fritter fritter={fritter} />
						</ParallaxLayer>

						<ParallaxLayer
							offset={-0.3}
							speed={0.16}
							style={{
								display: "flex",
								alignItems: "right",
								justifyContent: "center",
								flexDirection: "column",
								width: "100%",
								zIndex: 10,
								pointerEvents: "none"
							}}
						>
							<Fritter2 fritter={fritter2} />
						</ParallaxLayer>

						<ParallaxLayer
							offset={0.3}
							speed={0.1}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								width: "100%",
								paddingBottom: '100px'
							}}
						>
							<Container fluid={'xxl'}>
								<Row>
									<Col lg={4} xs={12} className='d-sm-flex flex-sm-column align-items-sm-center justify-content-sm-center'>
										<Grid>
											<CardContent />
										</Grid>
									</Col>
									<Col lg={4} xs={12}>
										<Grid>

										</Grid>
									</Col>
									<Col lg={4} xs={12}>
										<Grid>
											<AnimatedContent
												// ref={ref}
												isInView={isInView}
												halign='end'
												heading={["instant", "ordering"]}
												para='Every craving is heard and answered in real-time. The CRAVE_WARE app requires users to enter their details ahead of activating the software. This ensures when a craving is triggered, the AI knows exactly how, who and where to respond to.'
											/>
											<VideoContent video='sound' style={{}} />
										</Grid>
									</Col>
								</Row>
							</Container>
						</ParallaxLayer>
					</ParallaxLayer>

					{/* End 4 Background */}


					{/* Background 5 */}

					<ParallaxLayer
						offset={isMobile ? 4 : 4}
						speed={0}
						style={{
							top: `${dynamicWidth ? (dynamicPages * 100) : 0}px`,
						}}

					>
						<ParallaxLayer
							offset={isMobile ? -0.5 : -0.5}
							speed={-0.1}
							style={{
								zIndex: "10",
							}}
						>
							<AnimatedContent
								// ref={ref}
								isInView={isInView}
								heading={["how ", "it", "works"]}
								styleType='large'
								halign='center'
								para=''
								style={{ zIndex: "11" }}
							/>
						</ParallaxLayer>
						<LastVideo
							autoPlay
							loop
							muted
							style={{
								zIndex: "-1",
							}}
						>
							<source src={rrTShirtVideo} type='video/mp4' />
						</LastVideo>



						<Container fluid={'xxl'}>
							<Row>
								<Col lg={4} xs={12} className="d-flex align-items-end">
									<Grid>
										<AnimatedContent
											isInView={isInView}
											heading={[""]}
											styleType={"small"}
											halign='start'
											para='Each CRAVE_WARE shirt includes a specially designed pod sewn into inside of the garment. The pod includes a wireless microphone that actively listens for tummy rumbles. Utilising the CNN, it distinguishes and separates stomach rumbles from bodily sounds and background noise. So the tech only answers to verified hunger triggers.'
										/>
										<RevolvingMic scrollValue={scroll} />
										<AnimatedContent
											isInView={isInView}
											styleType={"small"}
											heading={["Built-in microphone"]}
											halign='center'
											para='The built-in microphone actively listens to your stomach. Supported by the AI, it uses Bluetooth to send data to the app scanning the sounds.'
										/>
									</Grid>
								</Col>
								<Col lg={4} xs={12}>
									<Grid>

									</Grid>
								</Col>
								<Col lg={4} xs={12} className="d-flex align-items-end">
									<Grid>
										<PhoneImage />
										<AnimatedContent
											isInView={isInView}
											heading={["Smart rumbles recognition"]}
											styleType={"small"}
											halign='end'
											para='The trained AI only recognises verified stomach rumbles and ignores the rest. Once the app is setup and the software is activated, the shirt takes care of the rest.'
										/>
									</Grid>
								</Col>
							</Row>
						</Container>

					</ParallaxLayer>
					{/* End 5 Background */}

					{/* Background 6 */}

					{/* <ParallaxLayer
						offset={isMobile ? 5 : 5}
						speed={0}
						style={{
							top: `${dynamicWidth ? (dynamicPages * 100) : 0}px`,
							background: `#A50115`
						}}
					>
						<RegisterForm />
					</ParallaxLayer> */}
					{/* End 6 Background */}

				</Parallax>
			</Containerz>
			<Footer position='absolute' />
		</>
	);
}

export default Home;