import { useState } from "react";
import Image from "react-bootstrap/Image";
import { media } from "./media/media";
import Styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import PlayImage from "../images/play.svg";

// Importing videos
import intro from "./video/INTRO_FILM.mp4";
import gamer from "./video/Gamer_Leader.mp4";
import sensory from "./video/SENSORY_FILM.mp4";
import sound from "./video/SOUND_FILM.mp4";
import office from "./video/Office_Worker.mp4";

// Placeholder images
import introPlaceholder from "../images/video-placeholder.jpg";
import gamerPlaceholder from "../images/gamer-placeholder.jpg";
import sensoryPlaceholder from "../images/videoPlaceholderSensory1.png";
// import soundPlaceholder from "../images/sound-placeholder.jpg";
import officePlaceholder from "../images/videoPlaceholderSensory2.png";
import { useRef } from "react";


const IFrame = Styled.iframe`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
`;

// Styled component for the video
const StyledVideo = Styled.video`
  width: 100%; // Fill the container
  height: 100%; // Fill the container
  object-fit: cover; // Cover the container fully
`;


const StyledImage = Styled(Image)`

    @media ${media.mobileS} {

    }

    @media ${media.tablet} {

    }

    @media ${media.laptop} {

    }
`;

const ImageWrapper = Styled.div`
  position: relative;
  display: inline-block;
  margin: 20px;
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${PlayImage}) no-repeat center center;
    background-size: 64px;
    transition: all 0.3s ease;
  }
    &:hover:after {
    transform: scale(1.2);
  }
`;

function VideoContent({video}) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const playVid = useRef(null);

   // Determine which video to play based on the prop
  const videoMap = {
    intro,
    gamer,
    sensory,
    sound,
    office
  };

    const placeholderMap = {
    intro: introPlaceholder,
    gamer: gamerPlaceholder,
    sound: officePlaceholder,
    sensory: sensoryPlaceholder,
    office: officePlaceholder,
  };

 const videoSrc = videoMap[video] || intro; // Default to intro if the video prop doesn't match
  const placeholderSrc = placeholderMap[video] || introPlaceholder; // Default to introPlaceholder if the video prop doesn't match

  return (
    <div className="">
      <ImageWrapper onClick={handleShow}>
        <StyledImage variant='top' src={placeholderSrc} width={"100%"} height={"auto"} />
      </ImageWrapper>
      <Modal show={show} onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          className="video-modal"
          data-bs-theme="dark"
          centered
          fullscreen
        >
        <Modal.Header closeButton ></Modal.Header>
        <Modal.Body>
          <div class="ratio ratio-16x9">
            <StyledVideo ref={playVid} controls={true} muted={false} autoPlay={true}>
              <source width="100%" height="auto" frameBorder="0" webkitallowfullscreen={true} mozallowfullscreen={true} allowfullscreen={true} src={videoSrc} type="video/mp4" />
            </StyledVideo>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VideoContent;
