import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Alert, Form, Button, Container, Row, Col } from "react-bootstrap";
import Toolbar from "../Toolbar";
import refresh from "../../images/refresh.svg";
const GoldButton = styled.button`
  width: 58px;
  height: 58px;
  background-color: #ff2600;
  border: 5px solid #ff2600;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  span {
    transition: all 0.4s;
    &.recording {
      background-color: #fff;
      border: 8px solid #fff;
    }
    &.record {
      background-color: #fff;
      border: 8px solid #fff;
      border-radius: 50%;
    }
  }
  &:focus {
    outline: none;
  }
`;

const RecordAgain = styled.button`
  background-color: transparent;
  color: #fff;
  border: none;
  display: block;
  margin: 10px auto;
  &:hover img {
    // on hover make spin
    transform: rotate(360deg);
    transition: all 0.4s;
  }
`;

const AudioPlayer = styled.audio`
  margin: 10px;
`;

const RumbleAudio = ({ setVolume, setStage }) => {
  const [audioData, setAudioData] = useState(null);
  const [audioSrc, setAudioSrc] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [countdown, setCountdown] = useState("00:00");
  const [formData, setFormData] = useState({
    firstName: "",
    phone: "",
    email: "",
    audioFile: null,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const mapNumber = (num) => {
    const maxRangeSize = 25;
    const groupSize = maxRangeSize / 6;
    const mappedNumber = Math.ceil(num / groupSize);
    return mappedNumber > 6 ? 6 : mappedNumber;
  };

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      source.connect(analyser);
      analyser.fftSize = 2048;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const options = { mimeType: "audio/webm" };

      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        if (MediaRecorder.isTypeSupported('audio/mp4')) {
          options.mimeType = 'audio/mp4';
        } else if (MediaRecorder.isTypeSupported('audio/wav')) {
          options.mimeType = 'audio/wav';
        } else {
          console.error('No supported MIME type found for MediaRecorder');
          return;
        }
      }
      const recorder = new MediaRecorder(stream, options);

      let chunks = [];
      recorder.ondataavailable = (event) => chunks.push(event.data);
      recorder.onstop = () => {
        const completeBlob = new Blob(chunks, { type: options.mimeType });
        setAudioData(completeBlob);
        const reader = new FileReader();
        reader.readAsDataURL(completeBlob);
        reader.onloadend = () => {
          setAudioSrc(reader.result);
          setStage(2);
        };
      };

      let countdownTime = 10;
      setCountdown(`00:${countdownTime.toString().padStart(2, "0")}`);

      const countdownInterval = setInterval(() => {
        countdownTime -= 1;
        setCountdown(`00:${countdownTime.toString().padStart(2, "0")}`);
        if (countdownTime <= 0) {
          clearInterval(countdownInterval);
          recorder.stop();
          setIsRecording(false);
          setCountdown("00:00");
        }
      }, 1000);

      let vol = 0;

      const calculateVolume = () => {
        analyser.getByteTimeDomainData(dataArray);
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          const value = dataArray[i] / 128 - 1;
          sum += value * value;
        }

        vol = Math.sqrt(sum / bufferLength);
        setVolume(mapNumber(vol * 100));
      };

      const volumeLoggingInterval = setInterval(calculateVolume, 100);

      recorder.start();
      setIsRecording(true);
      setTimeout(() => {
        setVolume(0);
        recorder.stop();
        clearInterval(volumeLoggingInterval);
        setIsRecording(false);
      }, 10000);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (files && name !== "email" && name !== "firstName" && name !== "phone") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    // Check if the form is valid
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      if (audioData && audioData.size <= 5242880) {
        const reader = new FileReader();

        reader.onloadend = async () => {
          const audioBase64 = reader.result;
          const base64Data = audioBase64.split(",")[1];

          const payload = {
            firstName: formData.firstName,
            email: formData.email,
            phone: formData.phone,
            rumble: base64Data,
          };

          try {
            const response = await fetch("https://rumble.crave-ware.com/rumble", {
              method: "POST",
              body: JSON.stringify(payload),
              // mode: "no-cors",
              redirect: "follow",
            });

            if (response.status === 200) {
              setAlertMessage("Form submitted successfully.");
              navigate("/thanks");
            } else {
              setAlertMessage("There was an error submitting the form.");
            }
          } catch (error) {
            console.error("Error submitting form:", error);
            setAlertMessage("Failed to submit the form.");
          }
        };

        reader.readAsDataURL(audioData);
      } else if (!audioData) {
        setAlertMessage("Please record some audio before submitting.");
      } else {
        setAlertMessage("Audio file size exceeds 5MB limit.");
      }
    }
  };

  return (
    <>
      <Container className='pb-5 pt-2'>
        <Row className='justify-content-md-center'>
          <Col xs={12} md={6}>
            <p className='fs-1' style={{ height: "24px" }}>
              {isRecording && countdown}
            </p>
            <Form className='d-flex text-start flex-column gap-4' noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className='m-auto position-absolute' style={{ zIndex: "2", right: "0", left: "0", bottom: "20px" }} controlId='formAudio'>
                {!audioSrc && (
                  <GoldButton type='button' onClick={startRecording} disabled={isRecording}>
                    <span className={isRecording ? "recording" : "record"}></span>
                  </GoldButton>
                )}
              </Form.Group>
              <div className='m-auto'>
                {audioSrc && (
                  <>
                    <AudioPlayer className='m-auto' controls src={audioSrc}>
                      Your browser does not support the audio element.
                    </AudioPlayer>
                    <RecordAgain type='button' onClick={startRecording} disabled={isRecording}>
                      <img className='me-2 align-bottom' src={refresh} width={"24px"} height={"100%"} />
                      Record again
                    </RecordAgain>
                  </>
                )}
              </div>
              {audioSrc && (
                <>
                  <Toolbar stage={3} />
                  <Form.Group className='text-start' controlId='formFirstName'>
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control maxLength={12} required type='text' placeholder='' name='firstName' value={formData.firstName} onChange={handleChange} />
                  </Form.Group>

                  <Form.Group controlId='formPhone'>
                    <Form.Label>Phone*</Form.Label>
                    <Form.Control maxLength={12} required type='number' placeholder='' name='phone' value={formData.phone} onChange={handleChange} />
                  </Form.Group>

                  <Form.Group controlId='formEmail'>
                    <Form.Label>Email*</Form.Label>
                    <Form.Control required type='email' placeholder='' name='email' value={formData.email} onChange={handleChange} />
                  </Form.Group>
                  <Button className='rr-button' variant='primary' type='submit'>
                    Submit
                  </Button>
                  {alertMessage && <Alert variant='success'>{alertMessage}</Alert>}
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RumbleAudio;
