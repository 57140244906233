import styled from "styled-components";
import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Styled from "styled-components";
import Toolbar from "../components/Toolbar";
import MicListening from "../components/MicListening";
import RumbleAudio from "../components/form/rumbleAudio";

const StyledDiv = styled.div`
  background-color: #1a1818;
  color: #ffffff;
  text-align: center;
`;

const StyledHeading = Styled.h2`
    color: #ffffff;
    font-size: 24px;
    line-height: 0.8;
    margin-bottom: 0;
    font-family: "Goudy";
`;

function Rumble() {
  const [volume, setVolume] = useState(0);
  const [stage, setStage] = useState(1);

  return (
    <StyledDiv>
      <div style={{ background: "#A81614" }}>
        <Container className='py-3'>
          <Row>
            <Col md={12}>
              <StyledHeading>Submit your rumble</StyledHeading>
            </Col>
          </Row>
        </Container>
      </div>
      <Toolbar stage={stage} />
      <MicListening volume={volume} />
      <RumbleAudio setStage={setStage} setVolume={setVolume} />
    </StyledDiv>
  );
}

export default Rumble;
