import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import rotate_mic from './mic.gif';

// import image_1 from '../../images/mic_rotate/RR_Microphone_rotating_00001.png';
// import image_2 from '../../images/mic_rotate/RR_Microphone_rotating_00002.png';
// import image_3 from '../../images/mic_rotate/RR_Microphone_rotating_00003.png';
// import image_4 from '../../images/mic_rotate/RR_Microphone_rotating_00004.png';
// import image_5 from '../../images/mic_rotate/RR_Microphone_rotating_00005.png';
// import image_6 from '../../images/mic_rotate/RR_Microphone_rotating_00006.png';
// import image_7 from '../../images/mic_rotate/RR_Microphone_rotating_00007.png';
// import image_8 from '../../images/mic_rotate/RR_Microphone_rotating_00008.png';
// import image_9 from '../../images/mic_rotate/RR_Microphone_rotating_00009.png';
// import image_10 from '../../images/mic_rotate/RR_Microphone_rotating_00010.png';
// import image_11 from '../../images/mic_rotate/RR_Microphone_rotating_00011.png';
// import image_12 from '../../images/mic_rotate/RR_Microphone_rotating_00012.png';
// import image_13 from '../../images/mic_rotate/RR_Microphone_rotating_00013.png';
// import image_14 from '../../images/mic_rotate/RR_Microphone_rotating_00014.png';
// import image_15 from '../../images/mic_rotate/RR_Microphone_rotating_00015.png';
// import image_16 from '../../images/mic_rotate/RR_Microphone_rotating_00016.png';
// import image_17 from '../../images/mic_rotate/RR_Microphone_rotating_00017.png';
// import image_18 from '../../images/mic_rotate/RR_Microphone_rotating_00018.png';
// import image_19 from '../../images/mic_rotate/RR_Microphone_rotating_00019.png';
// import image_20 from '../../images/mic_rotate/RR_Microphone_rotating_00020.png';
// import image_21 from '../../images/mic_rotate/RR_Microphone_rotating_00021.png';
// import image_22 from '../../images/mic_rotate/RR_Microphone_rotating_00022.png';
// import image_23 from '../../images/mic_rotate/RR_Microphone_rotating_00023.png';
// import image_24 from '../../images/mic_rotate/RR_Microphone_rotating_00024.png';
// import image_25 from '../../images/mic_rotate/RR_Microphone_rotating_00025.png';
// import image_26 from '../../images/mic_rotate/RR_Microphone_rotating_00026.png';
// import image_27 from '../../images/mic_rotate/RR_Microphone_rotating_00027.png';
// import image_28 from '../../images/mic_rotate/RR_Microphone_rotating_00028.png';
// import image_29 from '../../images/mic_rotate/RR_Microphone_rotating_00029.png';
// import image_30 from '../../images/mic_rotate/RR_Microphone_rotating_00030.png';
// import image_31 from '../../images/mic_rotate/RR_Microphone_rotating_00031.png';
// import image_32 from '../../images/mic_rotate/RR_Microphone_rotating_00032.png';
// import image_33 from '../../images/mic_rotate/RR_Microphone_rotating_00033.png';
// import image_34 from '../../images/mic_rotate/RR_Microphone_rotating_00034.png';
// import image_35 from '../../images/mic_rotate/RR_Microphone_rotating_00035.png';
// import image_36 from '../../images/mic_rotate/RR_Microphone_rotating_00036.png';
// import image_37 from '../../images/mic_rotate/RR_Microphone_rotating_00037.png';
// import image_38 from '../../images/mic_rotate/RR_Microphone_rotating_00038.png';
// import image_39 from '../../images/mic_rotate/RR_Microphone_rotating_00039.png';
// import image_40 from '../../images/mic_rotate/RR_Microphone_rotating_00040.png';
// import image_41 from '../../images/mic_rotate/RR_Microphone_rotating_00041.png';
// import image_42 from '../../images/mic_rotate/RR_Microphone_rotating_00042.png';
// import image_43 from '../../images/mic_rotate/RR_Microphone_rotating_00043.png';
// import image_44 from '../../images/mic_rotate/RR_Microphone_rotating_00044.png';

// let wrapper = [ image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8,  image_9,  image_10,
//                 image_11, image_12, image_13, image_14, image_15, image_16, image_17, image_18, image_19, image_20,
//                 image_21,  image_22,  image_23,  image_24,  image_25,  image_26,  image_27,  image_28,  image_29,  image_30,
//                 image_31,  image_32,  image_33,  image_34,  image_35,  image_36,  image_37,  image_38,  image_39,  image_40,
//                 image_41,  image_42,  image_43,  image_44

// ];

const Frame = styled.img`
  width: 200px;
  height: auto;
`;

const RevolvingMic = ({ scrollValue }) => {

  // const [currentFrame, setCurrentFrame] = useState(0);
  // const FRAMES = 43;

  // useEffect(() => {
  //   const scrollHeight = 100;
  //   const scrollFraction = (scrollValue % scrollHeight) / scrollHeight;
  //   let frameIndex = Math.ceil(scrollFraction * FRAMES);

  //   if (frameIndex < 1) {
  //       frameIndex = 1;
  //   } else if (frameIndex > FRAMES) {
  //       frameIndex = FRAMES;
  //   }
  
  //   //setCurrentFrame(frameIndex);

  // }, [scrollValue]);

  return (
    <Frame src={rotate_mic} alt={'mic'} />
  );
};

export default RevolvingMic;