import React from 'react';
import { media } from '../../media/media';
import styled, { keyframes } from "styled-components";
import randd from "../../../images/rrandd.svg";
import { useSpring, animated, easings } from '@react-spring/web';

const Element = styled.div`
    
    @media ${media.mobileS} {
        width: 90vw;
    }

    @media ${media.tablet} {
        width: 40vw;
    }

    @media ${media.laptop} { 
        width: 30vw;
     }  
`;

const Rrnd = () => {

  const dropBounce = useSpring({
    from: { opacity: 0, scale: 2 },
    to: { opacity: 1, scale: 1 },
    config: { duration: 800, delay: 300, easing: easings.easeOutBounce }
  });

  return (
    <Element>
      <animated.div 
        style={dropBounce}>
          <img src={randd} style={{ width: "100%", marginLeft: '0%' }} />
      </animated.div>
    </Element>
  )
}

export default Rrnd;