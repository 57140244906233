import Card from "react-bootstrap/Card";
import Submit from "../images/submit_win.svg";
import { media } from "./media/media";
import Styled from "styled-components";
import drumsticks from "../images/drumsticks.png";
import { Link } from "react-router-dom";
import arrow from "../images/arrow_right_alt.svg";

const StyledCard = Styled(Card)`

  text-align: left;
  padding: 16px;
  background: #000;
  padding-top: 40px;
  opacity: 0;

  @media ${media.mobileS} {
        width: 90vw;
        margin: auto;
        margin-top: 0px;
    }

    @media ${media.tablet} {
        width: 40vw;
        margin-top: 0px;
    }

    @media ${media.laptop} {
        display: flex;
        max-width: 400px;
        width: 100%;
        /* margin-left: 40px; */
        flex-direction: column;
        align-items: start;
        transform: translate3d(0, 20px, 0);
    }
`;

const Image = Styled.img`
    width: 90%;
    margin: auto;
`;

const DrumSticks = Styled.img`
    width: 140%;
    margin-left: -25%;
`;

const StyledLink = Styled(Link)`
    img {

        transition: transform .2s;
    }
    &:hover img{
        transform: translateX(5px);
    }
`

function DarkCardContent() {
  return (
    <StyledCard className='rounded-2'>
      <Image src={Submit} />
      <Card.Body className='p-0 pb-3'>
        <DrumSticks src={drumsticks} />
        <StyledLink className='btn rr-button d-inline btn-primary fs-6 mt-0 mb-2' to={"#"}>
          Submit <span className="d-none d-xl-inline">your </span>rumble <img className="d-none d-xl-inline" src={arrow} width={'24px'} height={'auto'} />
        </StyledLink>
      </Card.Body>
    </StyledCard>
  );
}

export default DarkCardContent;
