import React, { useState }  from 'react';
import { media } from '../../media/media';
import styled from 'styled-components';
import { useSpring, animated, easings } from '@react-spring/web';

const Container = styled.div`

    position: absolute;
    zIndex: -1;

    @media ${media.mobileS} {
        width: 50%;
        left: 60vw;
        top: 55vh;
        zIndex: 0;
    }

    @media ${media.tablet} {
        width: 30%;
        left: 54vw;
        top: 50vh;
        zIndex: 0;
    }

    @media ${media.laptop} { 
        width: 30%;
        left: 54vw;
        top: 46vh;
        zIndex: 0;
    }  
`;

const Drumstick = ({drumstick}) => {

    const [startLoop, setStartLoop] = useState(false);

    const sideSlide = useSpring({
        from: { opacity: 0, scale: 0.4, x: "100%", rotate: -180 },
        to: { opacity: 1, scale:1, x: "0%", rotate: 0 },
        config: { duration: 600, delay: 500, easing: easings.easeInCubic },
        onRest: () => setStartLoop(true),
    });

    const loopStyles = useSpring({
        to: [
          { transform: 'translateY(-10px)' },
          { transform: 'translateY(10px)' },
          { transform: 'translateY(0px)' },
        ],
        from: { transform: 'translateY(0px)' },
        config: { duration: 5000  },
        loop: startLoop,
        immediate: !startLoop,
    });

    return (
        <Container>
            <animated.div 
                style={startLoop ? loopStyles : sideSlide}>
                <img src={drumstick} style={{ display: "block", width: "100%" }}  />
            </animated.div>
        </Container>
    );
};

export default Drumstick;