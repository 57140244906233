import styled from "styled-components";
import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Styled from "styled-components";
import Toolbar from "../components/Toolbar";
const StyledDiv = styled.div`
  background-color: #1a1818;
  color: #ffffff;
  text-align: center;
`;

const StyledHeading = Styled.h2`
    color: #ffffff;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0;
    font-family: "Goudy";
`;

const HeadingBody = styled.p`
  color: #fff;
  text-align:center;
  font-size: 16px;
  line-height: 2opx;
`;

function Thanks() {
  return (
    <StyledDiv>
      <div style={{ background: "#A81614" }}>
        <Container className='py-3'>
          <Row>
            <Col md={12}>
              <StyledHeading>Thank you for registering for crave_ware</StyledHeading>
              <HeadingBody>RR&apos;N&apos;D will be in touch with future updates on crave_ware&apos;s development and release.</HeadingBody>
            </Col>
          </Row>
        </Container>
      </div>

    </StyledDiv>
  );
}

export default Thanks;
