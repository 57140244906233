import React  from 'react';
import { media } from '../../media/media';
import styled from 'styled-components';
import { useInView, animated } from '@react-spring/web'
import { buildInteractionObserverThreshold } from './helper/threshold'

const Container = styled.div`

    position: absolute;
    zIndex: -1;

    @media ${media.mobileS} {
        width: 30%;
        left: 0;
        top: 0;
        zIndex: 1;
    }

    @media ${media.tablet} {
        width: 30%;
        left: 0;
        top: 0;
        zIndex: 1;
    }

    @media ${media.laptop} { 
        width: 30%;
        left: 0;
        top: 0;
        zIndex: 1;
    }  
`;

const Mic = ({mic}) => {

    const [ref, springs] = useInView(
        () => ({
          from: {
            opacity: 1,
            rotate: -60,
            x: -300,
          },
          to: {
            opacity: 1,
            rotate: 0,
            x: 0,
          },
        }),
        {
          rootMargin: '-45% 0px -45% 0px',
          amount: buildInteractionObserverThreshold(),
        }
      )

    return (
        <Container>
            <animated.div ref={ref} style={springs}>
                <img src={mic} style={{ display: "block", width: "100%" }}  />
            </animated.div>
        </Container>
    );
};

export default Mic;