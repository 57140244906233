import React from "react";
import styled from "styled-components";
import VolumeCircle from "./VolumeCircle";
import mic from "../images/mic2.png";

const Image = styled.div`
  text-align: center;
  position: relative;
  padding: 60px 20px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function MicListening({ volume }) {

  return (
    <Image>
      <VolumeCircle volume={volume} />
      <img src={mic} className='rounded-2 z-2' alt='Mic' />
    </Image>
  );
}
