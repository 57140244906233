import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Alert, Form, Button, Container, Row, Col } from "react-bootstrap";

const FormTitle = styled.h3`  
  margin-top: 75px;
  color: #fff;
  text-align:center;
`;
const FormBody = styled.p`
  color: #fff;
  text-align:center;
`;


const RegisterForm = ({ setVolume, setStage }) => {

  const [formData, setFormData] = useState({
    firstName: "",
    phone: "",
    email: "",
    royalty: "",
  });

  const [validated, setValidated] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name !== "email" && name !== "firstName" && name !== "phone" && name !== "royalty") {
      setFormData((prevFormData) => ({
        ...prevFormData
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    // Check if the form is valid
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
  
      const payload = {
        firstName: formData.firstName,
        email: formData.email,
        phone: formData.phone,
        royalty: formData.royalty,
      };

      try {
        const response = await fetch("https://rumble.crave-ware.com/rumble", {
          method: "POST",
          body: JSON.stringify(payload),
          // mode: "no-cors",
          redirect: "follow",
        });

        if (response.status === 200) {
          setAlertMessage("Form submitted successfully.");
          navigate("/thanks");
        } else {
          setAlertMessage("There was an error submitting the form.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setAlertMessage("Failed to submit the form.");
      }
    }
  };
  return (
    <>
      <Container className='pb-3 pt-2'>
        <Row className='justify-content-md-center'>
          <Col xs={12} md={6}>
            <Form className='d-flex text-start flex-column gap-4' noValidate validated={validated} onSubmit={handleSubmit}>
     
                <FormTitle>Craving your very own crave_ware t-shirt?</FormTitle>
                <FormBody>Register your interest below for future news and updates. As well as how you and your stomach can claim a crave_ware T-shirt.</FormBody>

                <Form.Group className='text-start' controlId='formFirstName'>
                  <Form.Label>First Name*</Form.Label>
                  <Form.Control maxLength={12} required type='text' placeholder='' name='firstName' value={formData.firstName} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId='formPhone'>
                  <Form.Label>Phone*</Form.Label>
                  <Form.Control maxLength={12} required type='number' placeholder='' name='phone' value={formData.phone} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId='formEmail'>
                  <Form.Label>Email*</Form.Label>
                  <Form.Control required type='email' placeholder='' name='email' value={formData.email} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId='formRoyaltyID'>
                  <Form.Label>Red Royalty ID*</Form.Label>
                  <Form.Control type='text' placeholder='' name='royalty' value={formData.royalty} onChange={handleChange} />
                </Form.Group>

                <Button className='rr-button' variant='primary' type='submit'>
                  Submit
                </Button>

                {alertMessage && <Alert variant='success'>{alertMessage}</Alert>}

            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RegisterForm;
