import React from 'react';
import { media } from '../../media/media';
import styled, { keyframes } from "styled-components";
import randd from "../../../images/cw.svg";


const Element = styled.div`
    
    @media ${media.mobileS} {
        width: 90vw;
    }

    @media ${media.tablet} {
        width: 80vw;
    }

    @media ${media.laptop} { 
        width: 80vw;
     }  
     
`;

const dropBounce = {
  hidden: { opacity: 0, scale:2 },
  visible: { opacity: 1, scale:1 },
  exit: { opacity: 0 }
};


export default function CraveWaveStatic() {
  return (
    <Element>
          <img src={randd} style={{ width: "100%", marginLeft: '0%' }} />
    </Element>
  )
}