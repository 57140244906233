import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FormHero from "../components/form/FormHero";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import { media } from "../components/media/media";

import Footer from "../components/Footer";
import step1 from "../images/famous-character-pokemon.svg";
import step2 from "../images/mobile-phone-hand-hold.svg";
import step3 from "../images/voice-id-approved.svg";
import step4 from "../images/form-validation-check-double.svg";
import drumstick from "../images/drumstick.png";
import drumstick2 from "../images/drumstick2.png";

const StyledHeading = Styled.h2`
	color: #A81614;
	font-size:40px;
	line-height: 1;
	margin-bottom: 28px;
	font-family: "Goudy";
`;

const StyledImage = Styled.img`
	margin: auto;
	max-width: 100px;
	height: auto;
	margin-bottom: 16px;
	z-index: 1;
`;

const Drumstick = Styled.img`

	position: absolute;
	z-index: 0;
	left: 20%;
	top:60px;

	@media ${media.mobileS} {
		width: 200px;
		left: 0;
		top: 150px;
    }

	@media ${media.tablet} {
		left: 10%;
		top: 50px;
		width: 300px;
    }

    @media ${media.laptop} {
		left: 20%;
		top: 50px;
		width: 300px;
    }
`;

const Drumstick2 = Styled.img`

	position: absolute;
	z-index: 0;

	@media ${media.mobileS} {
		width: 200px;
		right: 0;
		top: 0px;
    }

	@media ${media.tablet} {
		right: 10%;
		right: 0;
		width: 300px;
    }

    @media ${media.laptop} {
		right: 20%;
		top: 20px;
		width: 300px;
    }
`;

const Win = () => {
	return (
		<>
			<div style={{ background: "radial-gradient(88% 88% at 50% 50%, rgba(255, 65, 16, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #A81614" }}>
				<Container className='py-5'>
					<Row>
						<Col md={{ span: 12 }}>
							<FormHero />
							<Drumstick src={drumstick2} />
							<Drumstick2 src={drumstick} />
						</Col>
					</Row>
				</Container>
			</div>

			<div className='bg-white pb-5'>
				<Container className='py-5'>
					<Row>
						<Col sm={{ span: 12 }} md={{ span: 8, offset: 2 }}>
							<StyledHeading>How to record your rumbles</StyledHeading>
							<p>Let’s get you ready to rumble! All in four easy steps.</p>
							<p>
								Once your stomach is ready and craving loudly click the record button. We recommend holding your phone nice and close to your belly to ensure
								you catch those grumbles. You’ll then be given a chance to review your recorded rumble. We know stomachs can be quite camera shy so feel free to
								cancel and start again.
							</p>
							<p>
								Once you’re satisfied, enter your details into each field and click submit. And just like that you’re in the draw to win a year’s worth of free
								fried chicken – to keep those cravings at bay.
							</p>
							</Col>
							</Row>
							<Row>
								<Col sm={12} md={6} lg={3} className='frame my-5'>
									<StyledImage src={step1} width='100%' height='auto' alt='Double check icon' />
									<h3 className='fs-4'>Click record and get ready to rumble</h3>
									<p>A timer will count you down before the recording begins</p>
								</Col>
								<Col sm={12} md={6} lg={3} className='frame my-5'>
									<StyledImage src={step2} width='100%' height='auto' alt='Sound icon' />
									<h3 className='fs-4'>Hold your phone close to your stomach</h3>
									<p>Once the recording begins hold your device close to your body to ensure maximum clarity.</p>
								</Col>
								<Col sm={12} md={6} lg={3} className='frame my-5'>
									<StyledImage src={step3} width='100%' height='auto' alt='Mobile in hand icon' />
									<h3 className='fs-4'>Review your rumble</h3>
									<p>You’ll be given the ability to playback your rumble for review. Not satisfied? Simply start again.</p>
								</Col>
								<Col sm={12} md={6} lg={3} className='frame my-5'>
									<StyledImage src={step4} width='100%' height='auto' alt='Record Icon' />
									<h3 className='fs-4'>Submit your details & enter</h3>
									<p>Fill in your contact details and enter for your chance to win.</p>
								</Col>
							</Row>
							<Row>
							<Col md={{ span: 12 }}>
							<Link className='btn rr-button btn-primary mt-0 mb-2' to={"/rumble"}>
								Get Started
							</Link>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer position='relative' />
		</>
	);
};

export default Win;
