import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { media } from "./media/media";
import Styled from "styled-components";

const StyledContent = Styled.div`
  display: flex;
  justify-content: ${(props) => props.halign};
  flex-direction: column;
  text-align: left;
  padding: 40px 20px;
  width: 100%;
  max-width: ${(props) => (props.styleType === "large" ? "100%" : "600px")};
  padding-left: ${(props) => {
    if (props.styleType === "large") return "0";
    if (props.styleType === "small") return "20px";
    return "40px";
  }};
  h2 {
    color: ${(props) => (props.styleType === "small" ? "#ffffff" : "#A81614")};
    font-size: ${(props) => {
      if (props.styleType === "large") return "6rem"; // 96px
      if (props.styleType === "small") return "1.5rem"; // 24px
      return "4.2rem"; // 48px
    }};
    text-align: ${(props) => (props.styleType === "large" ? "center" : "left")};
    line-height: 0.8;
    margin-bottom: 28px;
    span {
      display: ${(props) => (props.styleType === "large" ? "inline" : "block")};
      font-family: ${(props) => (props.styleType === "small" ? "Founders Medium" : "Goudy")};
      &:nth-child(1){
        margin-left: ${(props) => {
          if (props.styleType === "large") return "0";
          if (props.styleType === "small") return "0";
          return "-40px";
        }};
      }
      &:nth-child(2) {
        margin-left: ${(props) => {
          if (props.styleType === "large") return "0";
          if (props.styleType === "small") return "0";
          return "20px";
        }};
      }
      &.last-item {
        font-size: 6rem; // Assuming 96px for large last item
        display: block;
      }
    }
  }

  p {
    color: #ffffff;
    font-family: "Founders Regular";
    font-size: 1.125rem; // 18px
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  @media ${media.tablet} {
    font-size: ${(props) => {
      if (props.styleType === "large") return "9.25rem"; // 148px
      if (props.styleType === "small") return "1.5rem"; // 24px
      return "3rem"; // 48px
    }};
  }

  @media ${media.laptop} {
    padding: 40px;
    max-width: ${(props) => (props.styleType === "large" ? "100%" : "420px")};
  }
    @media ${media.desktop} {
      font-size:115%;
    }
`;


const fadeInVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0 },
};

const fadeInParaVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const AnimatedContent = ({ heading, para, styleType, halign }) => {
  const controls = useAnimation();
  const paraControls = useAnimation();
  const [ref, inView] = useInView();
  const [headingAnimationComplete, setHeadingAnimationComplete] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start("visible").then(() => {
        setHeadingAnimationComplete(true);
      });
    }
  }, [controls, inView]);

  useEffect(() => {
    if (headingAnimationComplete) {
      paraControls.start("visible");
    }
  }, [paraControls, headingAnimationComplete]);

  return (
    <StyledContent styleType={styleType} halign={halign}>
      <motion.div ref={ref} variants={fadeInVariants} initial='hidden' animate={controls} transition={{ duration: 2 }}>
        <h2>
          {heading.map((item, index) => (
            <motion.span
              key={index}
              className={styleType === "large" && index === heading.length - 1 ? "last-item" : ""}
              variants={fadeInVariants}
              transition={{ delay: 0.2 * (index + 1) }}
            >
              {item}
            </motion.span>
          ))}
        </h2>
      </motion.div>
      <motion.p variants={fadeInParaVariants} initial='hidden' animate={paraControls} transition={{ duration: 2 }}>
        {para}
      </motion.p>
    </StyledContent>
  );
};

export default AnimatedContent;
